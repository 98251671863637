import * as React from 'react'

import Layout from '../components/layout'
import OurStory from '../components/OurStory/ourStory'
import SEO from '../components/seo'

const OurStoryPage = () => (
  <Layout>
    <OurStory />
  </Layout>
)

export default OurStoryPage

export const Head = () => (
  <SEO
    page='Our Story'
    description='Founded in 2019 by Kunthea Seng, our bar is dedicated to providing our customers with a unique and unforgettable experience.'
  />
)
