import React from 'react'
import PropTypes from 'prop-types'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import Social from '../assets/images/social.png'

const SEO = ({ title, description, pathname, children, page }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    facebookUrl,
    instagramUrl,
    linkedinUrl,
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
  }

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'CafeOrCoffeeShop',
    name: seo.title,
    url: seo.url,
    image: [`${siteUrl}${Social}`],
    sameAs: [facebookUrl, instagramUrl, linkedinUrl],
  }

  const schemaAsString = JSON.stringify(schema, null, 2)

  return (
    <>
      <title>
        {page} | {seo.title}
      </title>
      <meta name='description' content={seo.description} />
      <meta name='image' content={`${siteUrl}${Social}`} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:url' content={seo.url} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={`${siteUrl}${Social}`} />
      <meta property='og:site_name' content={seo.title} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={seo.title} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:url' content={seo.url} />
      <meta property='og:image' content={`${siteUrl}${Social}`} />
      <meta property='article:publisher' content={facebookUrl} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:url' content={seo.url} />
      <meta name='twitter:image' content={seo.title} />
      <script type='application/ld+json'>{schemaAsString}</script>
      {children}
    </>
  )
}

SEO.defaultProps = {
  description: ``,
  pathname: ``,
  children: ``,
  page: 'Home',
}

SEO.propTypes = {
  description: PropTypes.string,
  pathname: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  page: PropTypes.string,
}

export default SEO
