import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
// import { useProducts } from '../store'

const Header = ({ siteTitle }) => {
  const [showMenu, setShowMenu] = useState(false)
  // const products = useProducts()
  return (
    <header className='shadow-sm'>
      <div className='container'>
        <div className='d-flex justify-content-between bd-highlight navbar navbar-expand-md navbar-light'>
          <h1 className='logo font-secondary text-decoration-none no-wrap header-hide-logo-mobile'>
            <Link
              className={`text-decoration-none ${
                showMenu ? 'd-none' : 'd-block'
              }`}
              to='/'
            >
              {siteTitle}
            </Link>
          </h1>
          <nav
            className={`navbar navbar-expand-md navbar-light ${
              showMenu && 'nav-nav'
            }`}
          >
            <div
              className={`collapse navbar-collapse header-menu bg-white ${
                showMenu ? 'd-block mt-3' : 'd-none'
              }`}
              id='navbarTogglerDemo02'
            >
              <nav className='navbar-nav me-auto text-uppercase'>
                <li className='nav-item text-center'>
                  <Link
                    className='nav-link text-decoration-none fw-bold'
                    activeClassName='active'
                    aria-current='page'
                    to='/'
                  >
                    Home
                  </Link>
                </li>
                <li className='nav-item text-center'>
                  <Link
                    className='nav-link text-decoration-none fw-bold'
                    activeClassName='active'
                    to='/our-story'
                  >
                    Our Story
                  </Link>
                </li>
                <li className='nav-item text-center'>
                  <Link
                    className='nav-link text-decoration-none fw-bold'
                    activeClassName='active'
                    to='/menu'
                  >
                    menu
                  </Link>
                </li>
                {/* <li className='nav-item text-center'> */}
                {/*  <Link */}
                {/*    className='nav-link text-decoration-none fw-bold' */}
                {/*    activeClassName='active' */}
                {/*    to='/shop' */}
                {/*  > */}
                {/*    Shop */}
                {/*  </Link> */}
                {/* </li> */}
                <li className='nav-item text-center'>
                  <Link
                    className='nav-link text-decoration-none fw-bold'
                    activeClassName='active'
                    to='/event-hire'
                  >
                    Event Hire
                  </Link>
                </li>
                <li className='nav-item text-center'>
                  <Link
                    className='nav-link text-decoration-none fw-bold'
                    activeClassName='active'
                    to='/contact-us'
                  >
                    Contact Us
                  </Link>
                </li>
              </nav>
              {/* <div className='d-flex header-shopping-cart align-items-center'> */}
              {/*  <div className='col-auto'> */}
              {/*    <Link */}
              {/*      to='/cart' */}
              {/*      className='text-decoration-none font-black' */}
              {/*      aria-label='View your shopping cart' */}
              {/*    > */}
              {/*      <i className='icon-cart fs-2' /> */}
              {/*    </Link> */}
              {/*  </div> */}
              {/*  <div className='col-auto m-1'> */}
              {/*    <Link to='/cart'> */}
              {/*      <span className='cart-items fs-medium bg-dark align-items-center justify-content-center d-flex rounded-circle text-white p-1'> */}
              {/*        {products && products.length > 0 */}
              {/*          ? products.reduce( */}
              {/*              (sum, { quantity }) => sum + quantity, */}
              {/*              0 */}
              {/*            ) */}
              {/*          : 0} */}
              {/*      </span> */}
              {/*    </Link> */}
              {/*  </div> */}
              {/* </div> */}
            </div>
            <button
              className={`shadow-none navbar-toggler border-0 text-primary ${
                showMenu && 'nav-button'
              }`}
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarTogglerDemo02'
              aria-controls='navbarTogglerDemo02'
              aria-expanded='false'
              aria-label='Toggle navigation'
              onClick={() => {
                setShowMenu(!showMenu)
              }}
            >
              <i className={`icon-${showMenu ? 'cross' : 'menu'} fs-2`} />
            </button>
          </nav>
        </div>
      </div>
      <div
        className={`bg-white bottom-0 end-0 position-absolute start-0 top-0 z-index-1 d-md-none ${
          showMenu ? 'd-block' : 'd-none'
        } `}
      />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
