import React from 'react'
import { useSiteMetadata } from '../../hooks/use-site-metadata'

const Footer = () => {
  const {
    facebookUrl,
    instagramUrl,
    linkedinUrl,
    locations,
  } = useSiteMetadata()
  const currentYear = new Date().getFullYear() // Get the current year

  return (
    <footer className='bg-light text-center text-grey'>
      <div className='container p-2'>
        <div className='m-3'>
          <a
            className='btn btn-floating m-1'
            href={facebookUrl}
            role='button'
            target='_blank'
            rel='noreferrer'
            title='Connect to us on Facebook'
            aria-label='Connect to us on Facebook'
          >
            <i className='icon-facebook fs-1' />
          </a>
          <a
            className='btn btn-floating m-1'
            href={instagramUrl}
            role='button'
            target='_blank'
            rel='noreferrer'
            title='Follow us on Instagram'
            aria-label='Follow us on Instagram'
          >
            <i className='icon-instagram fs-1' />
          </a>
          <a
            className='btn btn-floating m-1'
            href={linkedinUrl}
            role='button'
            target='_blank'
            rel='noreferrer'
            title='Connect to us on Linkedin'
            aria-label='Connect to us on Linkedin'
          >
            <i className='icon-linkedin fs-1' />
          </a>
        </div>
        <div className='mb-4'>
          {locations.map((location) => (
            <p key={location.name} className='fw-bold'>
              {location.address}
            </p>
          ))}
        </div>
        <div className='mb-4'>
          <p>© {currentYear} Panda Cup all rights reserved</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
